.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.not-found-title {
  font-size: 5rem;
  margin: 0;
}

.not-found-message {
  font-size: 1.5rem;
  color: #666;
}

.not-found-link {
  margin-top: 20px;
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
}

.not-found-link:hover {
  text-decoration: underline;
}
